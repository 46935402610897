<template>
  <div class="md-layout-item md-xlarge-size-20 md-large-size-20 md-small-size-50 gallery-image-wrapper" style="position:relative" v-if="galleryImage">
      <!--  NEW IMAGES  -->
<!--      <div v-if="status === 'new'">{{galleryImage}}</div>-->
      <img
          v-if="status === 'new'"
          :src="galleryImage.image"
          :alt="galleryImage.title"
          class="md-alignment-center-center"
      />
      <!--  FOR APPROVED IMAGES  -->
      <img
          v-else-if="status === 'approved'"
          :src="imagePrefix + galleryImage.imagePath"
          :alt="galleryImage.title"
          class="md-alignment-center-center"
      />

      <!--  FOR PENDING MODERATION IMAGES  -->
      <img
          v-else-if="status === 'pending'"
          :src="imagePrefix + galleryImage.changes.attributes.image_path"
          :alt="galleryImage.title"
      />

      <md-button
          v-if="showEdit"
          class="md-fab md-raised md-primary md-mini edit-image"
          @click="editImageClick()"
      >
        <md-icon>edit</md-icon>
      </md-button>

      <md-button
          class="md-fab md-raised md-accent md-mini delete-image"
          @click="deleteImage()"
      >
        <md-icon>delete</md-icon>
      </md-button>

      <md-dialog-confirm
          :md-active.sync="showConfirmDelete"
          md-title="Delete this image?"
          md-content="Are you sure you want to delete this image? This action cannot be undone."
          md-confirm-text="Yes"
          md-cancel-text="No"
          @md-cancel="showConfirmDelete = false"
          @md-confirm="deleteImage(true)" 
      />
      <gallery-image-form
          v-if="showEditImageForm"
          @close="showEditImageForm = false"
          @save="saveImageUpdates"
          :image-path="imagePrefix + galleryImage.imagePath"
          :orig-description="galleryImage.description"
          :orig-title="galleryImage.title"
          :edit="true"
      ></gallery-image-form>
  </div>
</template>

<script>
import GalleryImageForm from "@/components/Admin/Forms/GalleryImageForm";
export default {
  name: "GalleryImage",
  components: {GalleryImageForm},
  data() {
    return {
      galleryImage: null,
      showConfirmDelete: false,
      showEditImageForm: false,

    }
  },
  props: {
    origGalleryImage: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      default: 'approved'
    }
  },

  computed: {
    imagePrefix() {
      // if(window.location.origin === "https://connect-staging.rehab.com" || window.location.origin === 'http://localhost:8080'){
      if(window.location.origin === 'http://localhost:8080'){
        return 'https://s3.amazonaws.com/rehab-test-upload/'
      }else {
        return 'https://s3.amazonaws.com/rehab-facility/'
      }
    },

    showEdit() {
      if(this.status === "pending" || this.status == "new"){
        return false;
      }
      return true;
    }


  },

  mounted() {
    if(this.origGalleryImage){
      this.galleryImage = JSON.parse(JSON.stringify(this.origGalleryImage));
    }
  },

  methods: {
    deleteImage(confirm) {
      // console.log('delete click')
      if(!confirm){
        this.showConfirmDelete = true;
        return;
      }
      this.$emit('deleteImage', {
        id: this.galleryImage.id,
        status: this.status
      })
    },

    editImageClick() {
      // console.log('edit click');
      this.showEditImageForm = true;
    },

    saveImageUpdates(event) {
      event.id = this.galleryImage.id
      this.$emit('editGalleryImage', event)
      this.galleryImage.title = event.title;
      this.galleryImage.description = event.description;
      this.showEditImageForm = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .md-dialog {
    border-radius: 10px;
  }
  .md-button {
    border-radius: 50%;
  }
  .md-fab {
    width: 30px;
    height: 30px;
    i {
      font-size:18px !important;
    }
  }

  .delete-image {
    position: absolute;
    right:    -10px;
    bottom:   -15px;
  }

  .edit-image {
    position: absolute;
    left:    5px;
    bottom:   -15px;
  }

  .gallery-image-wrapper {
    margin-bottom: 25px;
  }

  @media only screen and (max-width: 760px),
  screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
  screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
  screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
  {
    .gallery-image-wrapper {
      padding-right: 0 !important;
      margin-bottom: 25px;
      padding-left: 21px !important;
    }
    .delete-image {
      right: -15px;
    }
    .edit-image {
      left: 0;
    }
  }

</style>