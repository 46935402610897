<template>
  <md-dialog class="gallery-dialog" :md-active.sync="showDialog" @md-closed="$emit('close')" @md-clicked-outside="$emit('close')">
    <md-dialog-title>
<!--      <span v-if="!edit">Add image</span>-->
<!--      <span v-else>Update image</span>-->
    </md-dialog-title>
    <md-dialog-content>
      <div>
        <!-- IMAGE INPUT       -->
        <input
            v-if="!edit"
            type="file"
            ref="imageFile"
            @change="imageChange($event)"
            accept="image/*"
        />
      </div>
      <div class="spacer" v-if="showMdInvalidClass($v.croppedImage, submitted)"></div>
      <div class="md-error-text" v-if="showMdInvalidClass($v.croppedImage, submitted)"><md-icon class="error-icon">error</md-icon>Image is required</div>

      <!-- IMAGE CROPPER -->
      <cropper
          v-if="!edit"
          class="upload-cropper"
          :src="image"
          :stencil-props="{aspectRatio: null}"
          @change="onCropChange"
      />

      <!-- DISPLAY IMAGE IF EDITING -->
      <img
          v-else
          :src="imagePath"
          :alt="title"
          class="edit-image"
      >

      <!-- IMAGE TITLE -->
      <md-field :class="{'md-error': showMdInvalidClass($v.title, submitted)}">
        <label>Title</label>
        <md-input
            type="text"
            v-model="title"
            maxlength="255"
        />
        <span class="md-error-text" v-if="showMdInvalidClass($v.title, submitted)"><md-icon class="error-icon">error</md-icon>Title is required</span>
      </md-field>

      <!-- IMAGE DESCRIPTION -->
      <md-field :class="{'md-error': showMdInvalidClass($v.description, submitted)}">
        <label>Description</label>
        <md-input
            type="text"
            v-model="description"
            maxlength="255"
        />
        <span class="md-error-text" v-if="showMdInvalidClass($v.description, submitted)"><md-icon class="error-icon">error</md-icon>Description is required</span>
      </md-field>
    </md-dialog-content>

    <!-- SAVE AND CLOSE BUTTONS -->
    <md-dialog-actions>
      <md-button class="md-primary" @click="$emit('close')">Close</md-button>
      <md-button class="md-primary" @click="save()">Save</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, requiredIf } from 'vuelidate/lib/validators'
import customValidationMixin from '../../../mixins/validationMixin'
import { Cropper } from 'vue-advanced-cropper'

export default {
  name: "GalleryImageForm",

  components: {Cropper},

  mixins: [validationMixin, customValidationMixin],

  data() {
    return {
      image: null,
      mimeType: null,
      origImage: null,
      imageError: null,
      showImageEditor: false,
      showDialog: true,
      croppedImage: null,
      title: "",
      description: "",
      fileName: "",
      submitted: false,

    }
  },

  props: {
    edit: {
      type: Boolean,
      default: false,
    },

    imagePath: {
      type: String,
      default: null
    },

    origTitle: {
      type: String,
      default: null
    },

    origDescription: {
      type: String,
      default: null,
    }
  },

  validations: {
    title: {
      required,
      minLength: minLength(4)
    },
    description: {
      required,
      minLength: minLength(4)
    },
    croppedImage: {
      required: requiredIf(function(nestedModels){
        // console.log('EDITING?', this.edit)
        return !this.edit
      })
    }
  },

  mounted() {
    if(this.edit) {
      this.title = this.origTitle;
      this.description = this.origDescription;
    }else {
      this.$refs.imageFile.click();
    }
  },

  methods: {
    imageChange(event){
      // console.log('IMAGE CHANGE EVENT', event);
      const files = event.target.files
      const image = files[0]
      this.origImage = files[0];
      this.mimeType = image.type;
      this.fileName = image.name;
      // console.log('IMAGE SIZE', this.formatBytes(image.size))
      if(!image.name.includes('jpg') && !image.name.includes('jpeg') && !image.name.includes('png') && !image.name.includes('gif')){
        // console.error('invalid image type', image.name)
        this.imageError = true;
        return;
      }
      this.imageError = false;
      // this.image = image

      const reader = new FileReader();
      reader.onload = (e) => {
        // Read image as base64 and set to imageData
        this.image = e.target.result;

      };
      // console.log('THE READER', reader);
      reader.readAsDataURL(files[0]);

      this.showImageEditor = true;
    },


    onCropChange({canvas}){
      if(canvas){
        // console.log('THE CANVAS', canvas);
        canvas.toBlob(blob => {
          // console.log('the blob', blob)
          // this.croppedImage = URL.createObjectURL(blob);
          // this.image = new File([blob], this.origImage.name)
          // console.log('IMAGE PREVIEW SIZE', this.formatBytes(blob.size))
          // console.log('IMAGE FILE SIZE', this.formatBytes(this.information.image.size))
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            this.croppedImage = reader.result;
          }
        }, this.mimeType)
      }
    },

    save() {
      this.submitted = true;
      this.$v.$touch()
      if(this.$v.$error){
        return;
      }

      let data = {
        image: this.croppedImage,
        title: this.title,
        description: this.description,
        fileName: this.fileName
      }

      if(this.edit){
        data = {
          title: this.title,
          description: this.description,
        }
      }

      this.$emit('save', data)
    }
  }
}
</script>

<style lang="scss" scoped>
  .md-dialog {
    border-radius: 10px;
  }

  .gallery-dialog.md-dialog{
    min-width: 500px;
    padding: 20px;
    top: 44%;
  }
  .upload-cropper {
    margin-top: 25px;
    border: solid 1px #EEE;
    height: 200px;
    width: 100%;
    margin-bottom: 20px;
  }

  .edit-image {
    max-width: 300px;
  }

  .md-error-text {
    margin-top: 10px;
    font-size: 14px;
    color: red;
    .error-icon {
      color: red;
    }
  }
  .md-error {
    border-color: red;
    input {
      color: red;
    }
    label {
      color: red;
    }
    .md-error-text {
      position: absolute;
      left: 0;
      bottom: -25px;
      color: red;
      opacity: 1;
    }
  }
  .spacer {
    height: 5px;
  }




  @media only screen and (max-width: 760px),
  screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
  screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
  screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
  {
    .gallery-dialog.md-dialog {
      padding-left: 0;
      min-width: unset;
    }
    .upload-cropper {
      max-width: 100%;
    }
  }
</style>