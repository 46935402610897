<template>
  <form novalidate @submit.prevent="attemptSubmit">
    <md-card>
      <md-card-content v-if="data" class="media-form" :class="{'bulk-media': bulk}">
        <table>
          <tr v-if="bulk" class="select-all-row">
            <td>
              <md-checkbox v-model="selectAll" ></md-checkbox>
            </td>
            <td>
              <div class="selectAll">
                Select all properties
              </div>
            </td>
          </tr>
          <tr>
            <td v-if="bulk">
              <md-checkbox class="checkboxNormal" v-model="submitFields.video" />
            </td>
            <td>
              <label for="type">Video</label>
                <div class="video-wrapper" :class="{'md-invalid': showMdInvalidClass($v.videoInfo.content, true)}">
                  <select
                      placeholder="Type"
                      v-model="videoInfo.videoType"
                      name="videotype"
                      id="videotype"
                      @md-selected="formChanged=true"
                      :disabled="bulk && !submitFields.video"
                  >
                    <option value="youtube">YouTube</option>
                    <option value="vimeo">Vimeo</option>
                  </select>

                  <input
                      name="facility-facilityVideo"
                      id="facility-facilityVideo"
                      v-model="videoInfo.content"
                      placeholder="https://"
                      class="video-url"
                      @input="formChanged=true"
                      :disabled="bulk && !submitFields.video"
                  />
                  <!-- VIDEO -->
                  <span
                      class="video-error"
                      v-if="!$v.videoInfo.content.mustBeYoutubeOrVimeo"
                  ><md-icon class="error-icon">error</md-icon> Must be a valid URL for a YouTube or Vimeo video.</span>
                </div>

            </td>
          </tr>
          <tr>
            <td v-if="bulk">
              <md-checkbox class="checkboxNormal" v-model="submitFields.image" />
            </td>
            <td>
              <div class="md-layout md-gutter" :class="{'image-field': data.facility.imagePath || imagePreview || data.facility.pendingImagePath, 'profile-image-selected': bulk && imagePreview }">
                <div class="md-layout-item md-size-100">
                  <!-- PROFILE IMAGE -->
                  <label class="label">Profile picture</label>
                  <div class="images-wrapper">
                    <div
                        class="single-image-wrapper"
                        v-if="data.facility.imagePath"
                    >
                      <span class="image-status">Approved:</span>
                      <img
                          class="image"
                          :src="imagePrefix + data.facility.imagePath"
                      />
                    </div>
                    <div
                        class="single-image-wrapper"
                        v-if="data.facility.pendingImagePath || (bulk && imagePreview)"
                    >
                      <span class="image-status">Pending moderation:</span>
                      <img
                          class="image"
                          :src="bulk ? imagePreview : imagePrefix + data.facility.pendingImagePath"
                      />
                    </div>

                  </div>


                  <div
                      @click.stop="chooseProfilePhoto"
                      class="file-name-wrapper"
                  >
                    <md-button
                        :disabled="bulk && !submitFields.image"
                        class="md-raised choose-file"
                    >Choose file</md-button>
                    <span>{{ profileImageName ? profileImageName : 'No file chosen' }}</span>
                    <input
                        type="file"
                        ref="imageFile"
                        @change="imageChange($event)"
                        accept="image/*"
                        hidden
                        :disabled="bulk && !submitFields.image"
                    />
                  </div>

                  <span
                      class="image-error"
                      v-if="imageError"
                  ><md-icon class="error-icon">error</md-icon> Must be a PNG or JPG image</span>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="!bulk">
            <td>
              <div class="md-layout md-gutter gallery-wrapper" :class="{'no-profile-image': !data.facility.imagePath && !data.facility.pendingImagePath && !imagePreview}">
                <div class="md-layout-item md-size-100">
                  <!-- GALLERY IMAGES -->
                  <label class="label">Gallery</label>
                  <div class="gallery" v-if="gallery">
                    <span v-if="gallery.galleryImages.length > 0" class="image-status">Approved:</span>
                    <div class="md-layout md-gutter mobile-full-width">
                      <gallery-image
                          v-for="galleryImage in gallery.galleryImages"
                          :orig-gallery-image="galleryImage"
                          status="approved"
                          @deleteImage="deleteGalleryImage(galleryImage, 'approved')"
                          @editGalleryImage="updateGalleryImage($event)"
                      ></gallery-image>

                      <!-- ADD GALLERY IMAGE BUTTON  -->
                      <div
                          @click="showNewImageForm = true"
                          class="file-name-wrapper"
                          v-if="[...gallery.galleryImages, ...gallery.pendingModeration, ...newGalleryImages].length < 10"
                      >
                        <md-button
                            :disabled="bulk && !submitFields.image"
                            class="md-raised choose-file"
                        >Choose file</md-button>
                        <span>{{ 'No file chosen' }}</span>
                      </div>

                    </div>
                    <span class="image-status" v-if="(gallery.pendingModeration && gallery.pendingModeration.length) || newGalleryImages.length">Pending moderation:</span>
                    <div v-if="gallery.pendingModeration && gallery.pendingModeration.length || newGalleryImages.length" class="md-layout md-gutter">
                      <!-- PENDING IMAGES -->
                      <gallery-image
                          v-for="galleryImage in gallery.pendingModeration"
                          :key="galleryImage.id"
                          :orig-gallery-image="galleryImage"
                          :status="'pending'"
                          @deleteImage="deleteGalleryImage(galleryImage, 'pending')"
                      ></gallery-image>

                    </div>

                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>

      </md-card-content>
      <md-card-actions :class="{'actions' : !bulk , 'actions actionsBulk': bulk}">
        <md-button
            v-if="bulk"
            class="customGrey"
            @click.prevent="$emit('cancel')"
        >Cancel</md-button>
        <md-button
            class="md-raised md-primary"
            type="submit"
            :disabled="!formChanged"
        >
          <span>Save</span>
        </md-button>
      </md-card-actions>
    </md-card>

    <!-- PROFILE IMAGE EDITOR MODAL -->
    <md-dialog class="profile-image-dialog" :md-active.sync="showImageEditor">
      <md-dialog-content>
        <cropper
            class="upload-cropper"
            :src="image"
            :stencil-props="{aspectRatio: 1}"
            @change="onCropChange"
        />

        <div class="image-wrapper">
          <p>Preview:</p>
          <img :src="imagePreview" class="image-preview" alt="preview"/>
        </div>

      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary md-raised" @click="saveProfileImage">Save</md-button>
      </md-dialog-actions>
    </md-dialog>

    <!-- ADD GALLERY IMAGE MODAL  -->
    <gallery-image-form
        v-if="showNewImageForm"
        @close="showNewImageForm = false"
        @save="addGalleryImage($event)"
    ></gallery-image-form>

    <md-progress-spinner md-mode="indeterminate" class="bulk-media-spinner" v-if="bulk && bulkLoading"></md-progress-spinner>
  </form>


</template>

<script lang="javascript">
import { validationMixin } from 'vuelidate'
import customValidationMixin from '../../../mixins/validationMixin'
import { Cropper } from 'vue-advanced-cropper'
import GalleryImage from './GalleryImage'
import GalleryImageForm from './GalleryImageForm.vue'
import {mapActions} from 'vuex'

const mustBeYoutubeOrVimeo = (value) => {
  if(!value) return true
  if(!((value.includes('youtube.com') || value.includes('vimeo.com') || value.includes('https://youtu.be')))){
    return false
  }
  if(value.includes('youtube.com') || value.includes('youtu.be')){
    // console.log('IT IS YOUTUBE' )
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = value.match(regExp);
    if(!match) return false;
  }
  if(value.includes('vimeo')){
    // console.log('IT IS VIMEO' )
    const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
    const match = value.match(regExp);
    if(!match) return false
  }

  return true;
}

export default {
  name: "MediaForm",
  components: {Cropper, GalleryImage, GalleryImageForm},
  mixins: [validationMixin, customValidationMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    originalData: {
      type: Object,
      default: function(){
        return {
          facility: {}
        }
      }
    },

    originalVideoInfo: {
      type: Object,
      default: function() {
        return {}
      }
    },

    originalInformation: {
      type: Object,
      default: function() {
        return {}
      }
    },

    originalGallery: {
      type: Object,
      default: function() {
        return {galleryImages: [], pendingModeration: []}
      }
    },

    bulk: {
      type: Boolean,
      default: false
    }

  },

  data() {
    return {
      data: {
        facility: {}
      },
      formChanged: false,
      videoInfo: {},
      image: null,
      imageFile: null,
      information: {},
      selectAll: false,
      submitFields: {
        video: true,
        image: true
      },
      imageError: false,
      gallery: null,

      showImageEditor: false,
      showNewImageForm: false,
      imagePreview: null,
      mimeType: null,
      origImage: null,
      profileImageName: null,

      updatedGalleryImages: [],
      newGalleryImages: [],
      bulkLoading: false,
    }
  },

  computed: {
    imagePrefix() {
      // if(window.location.origin === "https://connect-staging.rehab.com" || window.location.origin === 'http://localhost:8080'){
      if(window.location.origin === 'http://localhost:8080'){
        return 'https://s3.amazonaws.com/rehab-test-upload/'
      }else {
        return 'https://s3.amazonaws.com/rehab-facility/'
      }
    },

    mobile() {
      return this.windowWidth <= 760
    }
  },

  watch: {
    selectAll(newVal, oldVal) {
      if( newVal === oldVal) return;

      for(let submitField in this.submitFields){
        this.submitFields[submitField] = newVal
      }
    },
    originalData: {
      deep: true,
      handler(originalData) {
        this.data = JSON.parse(JSON.stringify(originalData))
      }
    },
    originalVideoInfo: {
      deep: true,
      handler(originalVideoInfo) {
        this.videoInfo = JSON.parse(JSON.stringify(originalVideoInfo))
      }
    },
    originalInformation: {
      deep: true,
      handler(originalInformation){
        this.information = JSON.parse(JSON.stringify(originalInformation));
      }
    },
    originalGallery: {
      deep: true,
      handler(originalGallery){
        this.gallery = JSON.parse(JSON.stringify(originalGallery))
      }
    }
  },

  validations: {
    videoInfo: {
      content: {mustBeYoutubeOrVimeo},
      // videoType: {required}
    }
  },

  mounted() {
    this.data = JSON.parse(JSON.stringify(this.originalData))
    this.videoInfo = JSON.parse(JSON.stringify(this.originalVideoInfo))
    this.information = JSON.parse(JSON.stringify(this.originalInformation));
    this.gallery = JSON.parse(JSON.stringify(this.originalGallery))
    if(this.bulk){
      for(const submitField in this.submitFields){
        this.submitFields[submitField] = false;
      }
    }
  },

  methods: {
    ...mapActions([
        'deleteContentUpdate',
        'deleteAdminItem',
        'updateFacilityGalleryImage',
        'saveFacilityImage',
        'createFacilityGalleryImage'
    ]),
    async addGalleryImage(event){
      // console.log('ADD GALLERY IMAGE EVENT', event);
      this.$emit('startLoader')
      this.showNewImageForm = false;
      const {fileName, title, description, image} = event

      await this.createFacilityGalleryImage({
        fileName,
        title,
        description,
        file: image.split(',')[1],
        facilityId: this.$route.params.facilityId
      })
      this.$emit('loadData');
    },

    async updateGalleryImage(event){
      // console.log('UPDATE EVENT', JSON.parse(JSON.stringify(event)));
      event.facilityId = this.$route.params.facilityId
      await this.updateFacilityGalleryImage(event);
    },

    async deleteGalleryImage(imageData, status){
      if(status === 'pending'){
        await this.deleteContentUpdate(imageData.id);
      }else if(status === 'approved'){
        await this.deleteAdminItem({
          facilityId: this.$route.params.facilityId,
          type: 'gallery-image',
          childId: imageData.id
        })
      }
      this.$emit('loadData');
    },

    chooseProfilePhoto() {
      this.$refs.imageFile.click()
    },

    imageChange(event){
      // console.log('IMAGE CHANGE EVENT', event);
      const files = event.target.files
      const image = files[0]
      this.origImage = files[0];
      this.mimeType = image.type;
      // console.log('IMAGE SIZE', this.formatBytes(image.size))
      if(!image.name.includes('jpg') && !image.name.includes('jpeg') && !image.name.includes('png') && !image.name.includes('gif')){
        // console.error('invalid image type', image.name)
        this.imageError = true;
        return;
      }
      this.imageError = false;
      this.information.image = image
      this.profileImageName = image.name

      const reader = new FileReader();
      reader.onload = (e) => {
        // Read image as base64 and set to imageData
        this.image = e.target.result;

      };
      // console.log('THE READER', reader);
      reader.readAsDataURL(files[0]);

      this.showImageEditor = true;
      this.formChanged = true;
    },

    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },

    cropImage(event) {
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob(blob => {
          this.information.image = new File([blob], this.value.name)
          // this.information.image = blob;
        }, this.mimeType)
      }
    },

    onCropChange({canvas}){
      if(canvas){
        // console.log('THE CANVAS', canvas);
        canvas.toBlob(blob => {
          // console.log('the blob', blob)
          this.imagePreview = URL.createObjectURL(blob);
          this.information.image = new File([blob], this.origImage.name)
          // console.log('IMAGE PREVIEW SIZE', this.formatBytes(blob.size))
          // console.log('IMAGE FILE SIZE', this.formatBytes(this.information.image.size))
        }, this.mimeType)
      }
    },

    async saveProfileImage(){
      if(this.bulk){
        this.bulkLoading = true;
      }
      this.$emit('startLoader')
      this.showImageEditor = false
      // console.log('SAVE PROFILE IMAGE CLICK')
      const fd = new FormData()
      fd.append('file', this.information.image, this.information.image);
      fd.append('filename', this.information.image.name)
      fd.append('filetype', this.information.image.type)

      if(this.bulk){
        this.$emit('bulkSaveImage', fd)
      }else {
        await this.saveFacilityImage({
          formData: fd,
          facilityId: this.$route.params.facilityId
        })
        // console.log('SAVED IMAGE, EMITTING RELOAD DATA EVENT');
        this.$emit('loadData');
      }
    },

    stopBulkLoader() {
      this.bulkLoading = false;
    },

    validateForm() {
      this.$v.videoInfo.$touch()

      if(this.$v.videoInfo.$error){
        console.error('Error validating', this.$v.videoInfo)
        return false;
      }

      return true;

    },

    attemptSubmit(){
      if(this.validateForm() && !this.imageError){
        this.$emit('startLoader')

        const submitObject = {
          videoInfo: this.videoInfo,
          information: this.information,
          newGalleryImages: this.newGalleryImages,
          updatedGalleryImages: this.updatedGalleryImages
        }
        // console.log('SUBMIT OBJECT', submitObject);
        this.$emit('submit', {submitObject, submitFields: this.submitFields});
      }


    }


  }
}

</script>

<style lang="scss" scoped>


//.bulk-media{
//  margin-left: 24px;
//  margin-top: 0;
//  padding-top: 0;
//  margin-right: 24px;
//}

.actions{
  padding-bottom: 20px;
  padding-top: 0px;
  margin-right: 24px;
  max-width: 644px;
}

.file-name-wrapper {
  cursor: pointer;
  background-color: #f0f0f0;
  height: 45px;
  margin-left: 160px;
  max-width: 466px;
  border: 1px solid #dedede;
  border-radius: 5px;
  .choose-file {
    box-shadow: none;
    background-color: #fff;
    height: 31px;
    font-weight: 400;
  }
  .choose-file:hover {
    background-color: #fff;
  }
  span {
    line-height: 45px;
    color: #9e9e9e;
    margin-left: 5px;
    font-weight: 400;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
}

.gallery {
  .file-name-wrapper {
    margin-left: 20px;
    width: 466px;
  }
}

.actionsBulk{
  margin-right: 28px;
  padding-right: 43px;
  margin-top: 13px;
}

.bulk-admin{
  .media-form{
    padding: 0 41px;
  }
  .form-group{
    margin-bottom: 8px;
  }
  label{
    position: relative;
    top: -3px;
  }
  .video-wrapper {
    float:right;
    .video-url{
      width: 282px;
    }
  }

  .file-name-wrapper {
    margin-left: 127px;
    margin-right: 10px;
    margin-top: -22px;
  }

  .profile-image-selected{
    height: 190px;
  }

  .images-wrapper{
    left: 195px;

    .single-image-wrapper{
      top: 10px;
    }
  }

}

.delete {
  height: 34px;
  width: 35px;
  position: absolute;
  right:0;
  top: 0;
}

.icon{
  display: inline;
  .md-icon{
    color: #000!important;
    font-size: 20px!important;
  }
  :hover{
    cursor: pointer;
  }
}

.md-card{
  margin-left: 0px;
  width: 100%;
  margin-right: 0px;
  box-shadow: none;
}

.md-menu-content.md-select-menu{
  top: 413px!important;
  width: 14.5%!important;
}

.upload{
  .label{
    position: absolute;
    left: 0px;
  }
  input{
    margin-left: 160px;
    margin-bottom: 0px;
  }

}
//.md-file{
//  display: inline;
//}

.image-field {
  height: 100px;
}

.image-status {
  font-style: italic;
  font-size: 14px;
  font-weight: 400;
  color: #9e9e9e;
  white-space: nowrap;
  display: block;
}

.images-wrapper {
  position: absolute;
  top: 175px;
  left: 179px;
  .single-image-wrapper{
    display: inline-block;
    position: relative;
    top: -20px;
    .image-status {
      margin-bottom: 5px;
      //position: absolute;
      //top: -16px;




    }
    .image{
      border-radius: 50%;
      height: 100px;
    }
  }
  .single-image-wrapper:nth-child(2) {
    margin-left: 25px;
  }

}

.bulk-media-spinner{
  margin-right: auto;
  margin-top: -44%;
  position: absolute;
  left: 50%;
  margin-left: -40px;
  z-index: 999;
}



.image-upload-button{
  margin-left: 160px;
  margin-top: 60px;
}

.image-upload-button-no-image {
  margin-top: 0;
}

.image-upload-disabled {
  .md-button {
    background-color: rgba(0, 0, 0, 0.12) !important;
    cursor: default !important;
  }
}

select, input{
  margin-left: 160px;
  cursor: pointer;
  outline: none;
  border: 1px solid #eee;
  height: 46px;
  border-radius: 5px;
  padding-left: 16px;
  -webkit-text-fill-color: unset !important;
  width: 456px;
  margin-bottom: 26px;
  font-size: 15px;
  color: #43484f;
  padding-right: 26px;

}

select, input::after {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98.5%;
  background-position-y: 10px;
}

input{
  cursor: auto;
  -webkit-appearance: none;
}

#videotype{
  width: 110px;
}
.video-url{
  margin-left: 10px;
  width: 346px;
}
.video-error {
  color: #d93025 !important;
  display: block !important;
  position: absolute !important;
  top: 63px;
  font-size: 12px;
  left: 282px;
  .error-icon{
    font-size: 16px!important;
    color: #d93025!important;
  }
}

.image-error {
  color: #d93025 !important;
  display: block !important;
  position: absolute !important;
  top: 142px;
  font-size: 12px;
  left: 175px;
  .error-icon{
    font-size: 16px!important;
    color: #d93025!important;
  }
}

.upload-cropper {
  border: solid 1px #EEE;
  height: 200px;
  width: 100%;
}

.image-wrapper {
  width: 100%;
  text-align: center;
  p {
    margin-top: 15px !important;
    margin-bottom: 5px !important;
  }
  .image-preview {
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
  }
}


.bulk-media {
  margin-top:22px;
  label {
    position: relative;
  }
  select {
    margin-left: 0;
  }
  #facility-image{
    margin-left: 0 !important;
    margin-bottom: 0;
  }
}

.md-field.disabled:after{
  display: none!important;
}

.md-dialog.bulk-media .video-error{
  top: 129px;
  left: 80px;
}

.md-dialog {
  border-radius: 10px;
}

.profile-image-dialog {
  min-width: 500px;
    padding: 20px;
    top: 44%;
}

.image-container{
  display: block;
  min-height: 68px;
}

.gallery-wrapper {
  padding-top: 110px;
  display: block;
  margin-bottom: 50px;
  .image-status {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .gallery {
    margin-left: 160px;
    .md-layout {
      max-width: 100%;
    }
  }
}

.no-profile-image {
  padding-top: 15px;
}

.add-gallery-image-btn {
  margin: 0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  i {
    font-size:24px !important;
  }
}

.profile-image-btn {
  margin: 0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  i {
    font-size:24px !important;
  }
  i.small-edit {
    font-size:18px !important;
  }
}

@media only screen and (max-width: 960px){
  .video-wrapper{
    max-width: 350px;
  }
  .file-name-wrapper {
    max-width: 350px;
  }
}

@media only screen and (max-width: 760px),
screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
{
  #videotype{
    width: 100%;
  }
  .actions{
    padding-right: 0;
    padding-top: 20px;
  }
  .video-error{
    padding-left: 3px;
    padding-top: 3px;
    top: 145px !important;
  }
  .image-error{
    padding-left: 3px;
    padding-top: 3px;
    top: 210px;
    left: 18px;
  }
  .md-field {
    margin-left: 12px;
  }
  .image {
    top: 205px;
    left: 160px;
  }

  .image-field {
    .single-image-wrapper {
      top: 34px;
      left: 3px;
    }
  }

  .file-name-wrapper{
    margin-left: 14px;
    display: block;
    //margin-top: 30px;
    .image-status {
      top: 50px;
    }
    input{
      display: none !important;
    }
  }

  .image-upload-button {
    margin-top: 35px;
    margin-left: 130px;
    input {
      display: none !important;
    }
  }
  .profile-image-dialog {
    max-width:100%;
    min-width: unset;
    padding: 0;
    .upload-cropper {
      margin-right: auto;
      margin-left: auto;

      max-width: 90%;
    }
  }
  .profile-image-btn {
    top: -22px;
    left: 19px;
  }
  .add-gallery-image-btn {
    left: -7px;
  }

  .images-wrapper {
    top: 235px;
    left: 26px;
    .single-image-wrapper{
      display: inline-block;
      .image-status {
        position: absolute;
        top: -36px;
        white-space: nowrap;

      }
      .image{
        border-radius: 50%;
        height: 100px;
      }
    }
    .single-image-wrapper:nth-child(2) {
      margin-left: 25px;
    }

  }

  .gallery-wrapper {
    .gallery {
      margin-left: -2px;
      .image-status {
        margin-left: 16px;
      }
      .mobile-full-width {
        overflow: visible;
      }
    }
  }


}


</style>